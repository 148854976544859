import Api from "@/plugins/api"
import { authState } from '@/states/auth';
import BaseProvider from "./baseprovider";

export default class AuthProvider extends BaseProvider {
    /**
     * login
     */
    public async login(login: string, password: string,): Promise<any> {
        return Api.getOwnerCredentials(login, password);
    }

    /**
     * loginAsGlobalUser
     * @param globalUserUid string
     * @returns 
     */
    public async loginAsGlobalUser(globalUserUid: string, organizationUid: string): Promise<any> {
        const accessToken = await authState.dispatch('getAccessTokenAsync');

        return Api.getLoginAsOwnerCredentials(accessToken, globalUserUid, organizationUid);
    }
}