<template>
  <div v-if="visible" class='organization-system-banner__container'>
    <Toolbar :class="{ 'background-gradient-gold': active }">
      <template #start>
        <span :class="{ 'color-white': active }">{{ authState.state.organization?.name }}</span>
        <span v-if="active" class="ml-8 color-white">
          {{ $filters.capitalizeFirstLetter($t("logged_as")) }}
          <Chip :label="authState.state.globalUser?.name ?? ''" class="background-gradient-silver"></Chip>
        </span>
      </template>

      <template #end>
        <Button v-if="active" class="p-button p-button-rounded background-gradient-gold"
          icon="fas fa-right-from-bracket" @click="logoutAsButtonClicked"
          :label="$filters.capitalizeFirstLetter($t('back_to_my_session'))" iconPos="right"></Button>
        <Button v-else class="p-button p-button-rounded background-gradient-sunrise" icon="fas fa-arrow-right"
          @click="loginAsButtonClicked" :label="$filters.capitalizeFirstLetter($t('login_as'))"
          iconPos="right"></Button>
      </template>
    </Toolbar>

    <LoginAsDialog ref="loginAsDialog" @loginAsClicked="loginAsClicked" />
  </div>
</template>
<style lang="scss" scoped>
.p-toolbar {
  border-left: none;
  border-right: none;
  border-top: none;
}
</style>

<script lang="ts">
import LoginAsDialog from '@/components/OrganizationSystem/LoginAsDialog.vue'
import useProvider from '@/hooks/provider'
import { authState } from '@/states/auth'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  components: {
    LoginAsDialog,
  },
  setup() {
    const provider = useProvider();
    const loginAsDialog = ref();
    const router = useRouter();

    const active = ref(false);
    const visible = computed(() => authState.getters.currentOrganisation?.is_system || active.value);

    const loginAsButtonClicked = () => loginAsDialog.value.openDialog();

    const loginAsClicked = async (user: any) => {
      await authState.dispatch("loginAs", { globalUserUid: user.global_user_uid, organizationUid: user.organization_uid });
      await router.push({ name: authState.state.organization?.is_system ?? false ? 'credentials' : 'dashboardca' });
    };

    const logoutAsButtonClicked = async () => {
      await authState.dispatch("logoutAs");
      await router.push({ name: authState.state.organization?.is_system ?? false ? 'credentials' : 'dashboardca' });
    };

    const refreshLoggedAs = async () => {
      try {
        const systemAccessToken = await authState.dispatch('getSystemAccessTokenAsync');
        active.value = systemAccessToken && authState.state.globalUser && authState.state.organization;
      } catch (err) { active.value = false }
    };

    watch(() => authState.state.globalUser, refreshLoggedAs);

    onMounted(refreshLoggedAs);

    return {
      active,
      visible,
      authState,
      loginAsButtonClicked,
      loginAsDialog,
      loginAsClicked,
      logoutAsButtonClicked,
    };
  },
});
</script>

<style lang='scss'>
div.organization-system-banner__container {
  grid-area: organization-system-banner;
}
</style>
