import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "mr-2" }
const _hoisted_2 = {
  key: 0,
  class: "fas fa-check"
}
const _hoisted_3 = {
  key: 1,
  class: "fas fa-info-circle"
}
const _hoisted_4 = {
  key: 2,
  class: "fas fa-exclamation-circle"
}
const _hoisted_5 = {
  key: 3,
  class: "fas fa-exclamation-triangle"
}
const _hoisted_6 = { class: "" }
const _hoisted_7 = { class: "flex p-4" }
const _hoisted_8 = { class: "pl-2" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "bo-sidebar" }
const _hoisted_11 = { class: "bo-sidebar-header" }
const _hoisted_12 = ["src"]
const _hoisted_13 = {
  key: 0,
  class: "selector-container"
}
const _hoisted_14 = { id: "nav" }
const _hoisted_15 = { class: "appVersion" }
const _hoisted_16 = { class: "bo-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BOPending = _resolveComponent("BOPending")!
  const _component_BONotification = _resolveComponent("BONotification")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_entity_selector = _resolveComponent("entity-selector")!
  const _component_store_selector = _resolveComponent("store-selector")!
  const _component_SidebarLevel1 = _resolveComponent("SidebarLevel1")!
  const _component_SidebarLevel2 = _resolveComponent("SidebarLevel2")!
  const _component_OrganizationSystemBanner = _resolveComponent("OrganizationSystemBanner")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BOPending),
    _createVNode(_component_BONotification, { ref: "boNotification" }, null, 512),
    _createElementVNode("div", {
      class: _normalizeClass(["plugandpos-wrap", 
          `lang-${_ctx.currentRoute.params.locale} ` +
          `route-${_ctx.currentRoute.name as string} ` +
          (_ctx.showMenu ? ' menu-shown' : ' menu-hidden')
       ])
    }, [
      _createVNode(_component_Toast, { position: "top-center" }, {
        message: _withCtx((slotProps) => [
          _createElementVNode("div", {
            class: _normalizeClass(["flex justify-content-between align-items-center", 'severity-' + slotProps.message.severity])
          }, [
            _createElementVNode("div", _hoisted_1, [
              (slotProps.message.severity == 'success')
                ? (_openBlock(), _createElementBlock("i", _hoisted_2))
                : _createCommentVNode("", true),
              (slotProps.message.severity == 'info')
                ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                : _createCommentVNode("", true),
              (slotProps.message.severity == 'warn')
                ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                : _createCommentVNode("", true),
              (slotProps.message.severity == 'error')
                ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h4", null, _toDisplayString(slotProps.message.summary), 1),
              _createElementVNode("p", null, _toDisplayString(slotProps.message.detail), 1)
            ])
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_ConfirmDialog, {
        draggable: false,
        pt: {
          acceptButton: {
            label: _ctx.t('boolean_type_true')
          }
          , rejectButton: { label: _ctx.t('boolean_type_false') }
        }
      }, {
        message: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("i", {
              class: _normalizeClass(slotProps.message.icon),
              style: {"font-size":"1.5rem"}
            }, null, 2),
            _createElementVNode("p", _hoisted_8, _toDisplayString(slotProps.message.message), 1)
          ])
        ]),
        _: 1
      }, 8, ["pt"]),
      (_ctx.authState.state.globalUser)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("img", {
                  class: "plugandpos-icon",
                  width: "220",
                  src: require('@/assets/logo-restomax.svg')
                }, null, 8, _hoisted_12),
                (!(_ctx.authState.state.organization?.is_system ?? false))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(_component_entity_selector),
                      _createVNode(_component_store_selector)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_SidebarLevel1, {
                  show: _ctx.showMenu && !['login'].includes(_ctx.currentRoute.name as string),
                  "onLanguage:updated": _ctx.changeLanguage,
                  "onShow:updated": _cache[0] || (_cache[0] = (value) => _ctx.showMenu = value)
                }, null, 8, ["show", "onLanguage:updated"]),
                _createVNode(_component_SidebarLevel2)
              ]),
              _createElementVNode("div", _hoisted_15, "v" + _toDisplayString(_ctx.version), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_OrganizationSystemBanner),
        _createVNode(_component_router_view)
      ])
    ], 2)
  ], 64))
}