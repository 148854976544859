class ConfigModel {
    base_api: string = 'https://localhost:44329';
    base_v1: string = 'https://ws-back.plugandpos.com';
    base_v2: string = this.base_api;

    constructor(data: ConfigModel) {
        this.base_api = data.base_api || data.base_v2 || this.base_api;
        this.base_v1 = data.base_v1 || this.base_v1;
        this.base_v2 = data.base_v2 || data.base_api || this.base_v2;
    }
}

const data = await (await fetch('/config.json')).json();
console.log ('Loaded configuration file: ', data);

export default class Config {
    private static readonly config: ConfigModel = new ConfigModel(data);

    public static baseAPI (version: number = 2): string {
        switch (version) {
            case 2: return this.config.base_v2;
            case 1: return this.config.base_v1;
            default: throw new Error(`Base API version ${version} is not supported`);
        }
    }
}