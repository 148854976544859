import { createRouter, createWebHistory } from 'vue-router'
import { loadLocaleMessages, setI18nLanguage, SUPPORT_LOCALES } from './i18n'
import { authState } from '@/states/auth'

type SubModule = {
  clickAndCollect?: boolean,
  booking?: boolean
}

function load(component: string, subModule?: SubModule) {
  let prefix: string = ''

  if (subModule?.booking) {
    prefix = '/Booking'
  } else if (subModule?.clickAndCollect) {
    prefix = '/ClickAndCollect'
  }

  return () => import(`.${prefix}/views/${component}.vue`)
}

export function setupRouter(i18n: any) {
  const locale =
    i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value

  // setup routes
  const routes = [
    {
      path: '',
      name: 'root',
      component: load('Login'),
      meta: {
        isPublic: true,
      }
    },
    {
      path: '/:locale/',
      name: 'home',
      component: load('Login'),
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:locale/login',
      name: 'login',
      component: load('Login'),
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:locale/reset-password',
      name: 'resetpassword',
      component: load('ResetPassword'),
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:locale/change-password',
      name: 'changepassword',
      component: load('ChangePasswordWithToken'),
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:locale/dashboard',
      name: 'dashboard',
      component: load('Dashboard'),
      children: [
        {
          path: 'ca',
          name: 'dashboardca',
          component: load('Dashboard'),
        },
        {
          path: 'reportz',
          name: 'dashboardreportz',
          component: load('Dashboard'),
        },
        {
          path: 'itemlevels',
          name: 'dashboarditemlevels',
          component: load('Dashboard'),
        },
        {
          path: 'caglobalusers',
          name: 'dashboardcaglobalusers',
          component: load('Dashboard'),
        },
        {
          path: 'pointglobalusers',
          name: 'dashboardpointglobalusers',
          component: load('Dashboard'),
        },
        {
          path: 'salesdetails',
          name: 'dashboardsalesdetails',
          component: load('Dashboard'),
        },
        {
          path: 'listing/:listingId',
          name: 'dashboardlisting',
          component: load('Dashboard'),
        },
        {
          path: 'datawarehouse/:datawarehouseId',
          name: 'dashboarddatawarehouse',
          component: load('Dashboard'),
        },
      ]
    },
    {
      path: '/:locale/sale-quotes',
      name: 'sale-quotes',
      component: load('Sale/Quotes'),
      children: [
        {
          path: 'view/:uid',
          name: 'sale-quotes-view',
          component: load('Sale/Quotes')
        }
      ]
    },
    {
      path: '/:locale/sale-orders',
      name: 'sale-orders',
      component: load('Sale/Orders'),
      children: [
        {
          path: 'view/:uid',
          name: 'sale-orders-view',
          component: load('Sale/Orders')
        }
      ]
    },
    {
      path: '/:locale/sale-invoices',
      name: 'sale-invoices',
      component: load('Sale/Invoices'),
      children: [
        {
          path: 'view/:uid',
          name: 'sale-invoices-view',
          component: load('Sale/Invoices')
        }
      ]
    },
    {
      path: '/:locale/sale-deliveries',
      name: 'sale-deliveries',
      component: load('Sale/Deliveries'),
      children: [
        {
          path: 'view/:uid',
          name: 'sale-deliveries-view',
          component: load('Sale/Deliveries')
        }
      ]
    },
    {
      path: '/:locale/purchase-quotes',
      name: 'purchase-quotes',
      component: load('Purchase/Quotes'),
      children: [
        {
          path: 'view/:uid',
          name: 'purchase-quotes-view',
          component: load('Purchase/Quotes')
        }
      ]
    },
    {
      path: '/:locale/purchase-orders',
      name: 'purchase-orders',
      component: load('Purchase/Orders'),
      children: [
        {
          path: 'view/:uid',
          name: 'purchase-orders-view',
          component: load('Purchase/Orders')
        }
      ]
    },
    {
      path: '/:locale/purchase-deliveries',
      name: 'purchase-deliveries',
      component: load('Purchase/Deliveries'),
      children: [
        {
          path: 'view/:uid',
          name: 'purchase-deliveries-view',
          component: load('Purchase/Deliveries')
        }
      ]
    },
    {
      path: '/:locale/purchase-invoices',
      name: 'purchase-invoices',
      component: load('Purchase/Invoices'),
      children: [
        {
          path: 'view/:uid',
          name: 'purchase-invoices-view',
          component: load('Purchase/Invoices')
        }
      ]
    },
    {
      path: '/:locale/sales/:uid/:view',
      name: 'sale-edition',
      component: load('SaleEdition'),
    },
    {
      path: '/:locale/sales/:journalUid/new',
      name: 'sale-creation',
      component: load('SaleEdition'),
    },
    {
      path: '/:locale/sales/:journalUid/newfrom',
      name: 'sale-creation-from',
      component: load('SaleEdition'),
    },
    {
      path: '/:locale/purchases/:uid/:view',
      name: 'purchase-edition',
      component: load('PurchaseEdition'),
    },
    {
      path: '/:locale/purchases/:journalUid/new',
      name: 'purchase-creation',
      component: load('PurchaseEdition'),
    },
    {
      path: '/:locale/storetransfersflow/:uid/:view',
      name: 'storetransfer-flow-edition',
      component: load('StoreTransferEdition'),
    },
    {
      path: '/:locale/storetransfersflow/:journalUid/new',
      name: 'storetransfer-flow-creation',
      component: load('StoreTransferEdition'),
    },
    {
      path: '/:locale/storetransfersdirect/:uid/:view',
      name: 'storetransfer-direct-edition',
      component: load('StoreTransferEdition'),
    },
    {
      path: '/:locale/storetransfersdirect/:journalUid/new',
      name: 'storetransfer-direct-creation',
      component: load('StoreTransferEdition'),
    },
    {
      path: '/:locale/storetransfer-direct',
      name: 'storetransfer-direct',
      component: load('StoreTransfer/Direct'),
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-direct-view',
          component: load('StoreTransfer/Direct')
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-validating',
      name: 'storetransfer-flow-validating',
      component: load('StoreTransfer/FlowValidating'),
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-validating-view',
          component: load('StoreTransfer/FlowValidating')
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-preparing',
      name: 'storetransfer-flow-preparing',
      component: load('StoreTransfer/FlowPreparing'),
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-preparing-view',
          component: load('StoreTransfer/FlowPreparing')
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-refused',
      name: 'storetransfer-flow-refused',
      component: load('StoreTransfer/FlowRefused'),
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-refused-view',
          component: load('StoreTransfer/FlowRefused')
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-expedited',
      name: 'storetransfer-flow-expedited',
      component: load('StoreTransfer/FlowExpedited'),
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-expedited-view',
          component: load('StoreTransfer/FlowExpedited')
        }
      ]
    },
    {
      path: '/:locale/storetransfer-flow-delivered',
      name: 'storetransfer-flow-delivered',
      component: load('StoreTransfer/FlowDelivered'),
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-flow-delivered-view',
          component: load('StoreTransfer/FlowDelivered')
        }
      ]
    },
    {
      path: '/:locale/storetransfer-stock-correction',
      name: 'storetransfer-stock-correction',
      component: load('StoreTransfer/StockCorrection'),
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-stock-correction-view',
          component: load('StoreTransfer/StockCorrection')
        }
      ]
    },
    {
      path: '/:locale/storetransfer-stock-global-reset',
      name: 'storetransfer-stock-global-reset',
      component: load('StoreTransfer/StockGlobalReset'),
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-stock-global-reset-view',
          component: load('StoreTransfer/StockGlobalReset')
        }
      ]
    },
    {
      path: '/:locale/storetransfer-stock-global-transcription',
      name: 'storetransfer-stock-global-transcription',
      component: load('StoreTransfer/StockGlobalTranscription'),
      children: [
        {
          path: 'view/:uid',
          name: 'storetransfer-stock-global-transcription-view',
          component: load('StoreTransfer/StockGlobalTranscription')
        }
      ]
    },
    {
      path: '/:locale/items',
      name: 'items',
      component: load('Item/Items'),
      children: [
        {
          path: ':uid',
          name: 'item-edition',
          component: load('Item/Items')
        },
        {
          path: ':uid/:doc',
          name: 'item-edition-view',
          component: load('Item/Items')
        }
      ],
    },
    {
      path: '/:locale/stocks',
      name: 'stocks',
      component: load('Item/Stocks'),
      children: [
        {
          path: ':uid',
          name: 'stock-item-edition',
          component: load('Item/Items')
        },
      ],
    },
    {
      path: '/:locale/item-levels',
      name: 'item-levels',
      component: load('Item/ItemLevels'),
      children: [
        {
          path: ':uid',
          name: 'item-level-edition',
          component: load('Item/ItemLevels')
        },
      ],
    },
    {
      path: '/:locale/item-categories',
      name: 'item-categories',
      component: load('Item/ItemCategories'),
      children: [
        {
          path: ':uid',
          name: 'item-category-edition',
          component: load('Item/ItemCategories')
        },
      ],
    },
    {
      path: '/:locale/propositions',
      name: 'propositions',
      component: load('Item/Propositions'),
      children: [
        {
          path: ':uid',
          name: 'proposition-edition',
          component: load('Item/Propositions')
        },
      ],
    },
    {
      path: '/:locale/item-history',
      name: 'item-history',
      component: load('Item/History'),
      children: [
        {
          path: ':uid',
          name: 'item-history-list',
          component: load('Item/History')
        },
        {
          path: ':doc/:uid',
          name: 'item-history-view',
          component: load('Item/History')
        }
      ]
    },
    {
      path: '/:locale/item-ranks',
      name: 'item-ranks',
      component: load('Item/Ranks'),
      children: [
        {
          path: ':uid',
          name: 'item-rank-edition',
          component: load('Item/Ranks')
        }
      ]
    },
    {
      path: '/:locale/accounting/sales',
      name: 'accounting-sales',
      component: load('Accounting/Sales'),
      props: (r: any) => ({ entityId: +r.query.entityId })
    },
    {
      path: '/:locale/accounting/purchases',
      name: 'accounting-purchases',
      component: load('Accounting/Purchases'),
      props: (r: any) => ({ entityId: +r.query.entityId })
    },
    {
      path: '/:locale/pos',
      name: 'pos',
      component: load('Setting/POS'),
      children: [
        {
          path: ':uid',
          name: 'pos-edition',
          component: load('Setting/POS')
        },
        {
          path: ':uid/matrices',
          name: 'pos-matrices-edition',
          component: load('Setting/POS')
        }
      ],
    },
    {
      path: '/:locale/matrix-templates',
      name: 'matrix-templates',
      component: load('Matrix/MatrixTemplates'),
      children: [
        {
          path: ':uid',
          name: 'matrix-template-edition',
          component: load('Matrix/MatrixTemplates')
        },
        {
          path: ':uid/matrices',
          name: 'matrix-template-matrices-edition',
          component: load('Matrix/MatrixTemplates')
        },
      ]
    },
    {
      path: '/:locale/printers',
      name: 'printers',
      component: load('Setting/Printers'),
      children: [
        {
          path: ':uid',
          name: 'printer-edition',
          component: load('Setting/Printers')
        }
      ],
    },
    {
      path: '/:locale/customers',
      name: 'customers',
      component: load('Customer/Customers'),
      children: [
        {
          path: ':uid',
          name: 'customer-edition',
          component: load('Customer/Customers')
        },
        {
          path: ':uid/history',
          name: 'customer-history',
          component: load('Customer/Customers')
        },
        {
          path: ':uid/account',
          name: 'customer-account',
          component: load('Customer/Customers')
        },
      ],
    },
    {
      path: '/:locale/customer-categories',
      name: 'customer-categories',
      component: load('Customer/CustomerCategories'),
      children: [
        {
          path: ':uid',
          name: 'customer-category-edition',
          component: load('Customer/CustomerCategories')
        },
      ],
    },
    {
      path: '/:locale/customer-addresses',
      name: 'customer-addresses',
      component: load('Customer/CustomerAddresses'),
      children: [
        {
          path: ':uid',
          name: 'customer-address-edition',
          component: load('Customer/CustomerAddresses')
        },
      ],
    },
    {
      path: '/:locale/suppliers',
      name: 'suppliers',
      component: load('Supplier/Suppliers'),
      children: [
        {
          path: ':uid',
          name: 'supplier-edition',
          component: load('Supplier/Suppliers')
        },
        {
          path: ':uid/history',
          name: 'supplier-history',
          component: load('Supplier/Suppliers')
        },
        {
          path: ':uid/account',
          name: 'supplier-account',
          component: load('Supplier/Suppliers')
        },
      ],
    },
    {
      path: '/:locale/global-users',
      name: 'global-users',
      component: load('Setting/GlobalUsers'),
      children: [
        {
          path: ':uid',
          name: 'global-user-edition',
          component: load('Setting/GlobalUsers')
        },
      ],
    },
    {
      path: '/:locale/live-update',
      name: 'global:live-update',
      component: load('LiveUpdate/List', { clickAndCollect: true })
    },
    {
      path: '/:locale/my-organization',
      name: 'my-organization',
      component: load('Setting/MyOrganization'),
    },
    {
      path: '/:locale/glory-user-types',
      name: 'glory-user-types',
      component: load('Setting/GloryUserTypes'),
      children: [
        {
          path: ':uid',
          name: 'glory-user-type-edition',
          component: load('Setting/GloryUserTypes')
        },
      ],
    },
    {
      path: '/:locale/entities',
      name: 'entities',
      component: load('Setting/Entities'),
      children: [
        {
          path: ':uid',
          name: 'entity-edition',
          component: load('Setting/Entities')
        },
      ],
    },
    {
      path: '/:locale/stores',
      name: 'stores',
      component: load('Setting/Stores'),
      children: [
        {
          path: ':uid',
          name: 'store-edition',
          component: load('Setting/Stores')
        },
        {
          path: ':uid/matrices',
          name: 'store-matrices-edition',
          component: load('Setting/Stores')
        },
        {
          path: ':uid/printers',
          name: 'store-printers-edition',
          component: load('Setting/Stores')
        }
      ],
    },
    // {
    //   path: '/:locale/matrices',
    //   name: 'matrices',
    //   component: load('Matrices'),
    // },
    {
      path: '/:locale/settings',
      name: 'settings',
      component: load('Settings'),
    },
    {
      path: '/:locale/price-lists',
      name: 'price-lists',
      component: load('Setting/PriceLists'),
      children: [
        {
          path: ':uid',
          name: 'price-list-edition',
          component: load('Setting/PriceLists')
        },
      ],
    },
    {
      path: '/:locale/mail-templates',
      name: 'mail-templates',
      component: load('Setting/MailTemplates'),
      children: [
        {
          path: ':uid',
          name: 'mail-template-edition',
          component: load('Setting/MailTemplates')
        },
      ],
    },
    {
      path: '/:locale/webhooks',
      name: 'webhooks',
      component: load('Setting/Webhooks'),
      children: [
        {
          path: ':uid',
          name: 'webhook-edition',
          component: load('Setting/Webhooks')
        }
      ],
    },
    {
      path: '/:locale/account/dashboardlicenses',
      name: 'dashboardlicenses',
      component: load('Account/Licenses/Dashboard'),
    },
    {
      path: '/:locale/account/activelicenses',
      name: 'activelicenses',
      component: load('Account/Licenses/ActiveLicenses'),
    },
    {
      path: '/:locale/account/activecontracts',
      name: 'activecontracts',
      component: load('Account/Licenses/ActiveContracts'),
    },
    {
      path: '/:locale/account/demolicenses',
      name: 'demolicenses',
      component: load('Account/Licenses/DemoLicenses'),
      children: [
        {
          path: 'pos/:uid',
          name: 'demolicenses-pos-edition',
          component: load('Account/Licenses/DemoLicenses')
        },
        {
          path: 'entity/:uid',
          name: 'demolicenses-entity-edition',
          component: load('Account/Licenses/DemoLicenses')
        }
      ],
    },
    {
      path: '/:locale/account/blockedlicenses',
      name: 'blockedlicenses',
      component: load('Account/Licenses/BlockedLicenses'),
    },
    {
      path: '/:locale/account/inactiveslicenses',
      name: 'inactiveslicenses',
      component: load('Account/Licenses/InactivesLicenses'),
    },
    {
      path: '/:locale/account/unbindlicenses',
      name: 'unbindlicenses',
      component: load('Account/Licenses/UnbindLicenses'),
    },
    {
      path: '/:locale/account/emailhistory',
      name: 'emailhistory',
      component: load('Account/History/EmailHistory'),
    },
    {
      path: '/:locale/account/smshistory',
      name: 'smshistory',
      component: load('Account/History/SMSHistory'),
    },
    {
      path: '/:locale/account/organisationlogs',
      name: 'organisationlogs',
      component: load('Account/Other/OrganisationLogs'),
    },
    {
      path: '/:locale/account/entitiesprodunready',
      name: 'entitiesprodunready',
      component: load('Account/Other/EntitiesProdUnready'),
      children: [
        {
          path: ':uid',
          name: 'entitiesprodunready-entity-edition',
          component: load('Account/Other/EntitiesProdUnready')
        }
      ],
    },
    {
      path: '/:locale/account/entitiesprodready',
      name: 'entitiesprodready',
      component: load('Account/Other/EntitiesProdReady'),
      children: [
        {
          path: ':uid',
          name: 'entitiesprodready-entity-edition',
          component: load('Account/Other/EntitiesProdReady')
        }
      ],
    },
    {
      path: '/:locale/signalr',
      name: 'signalr',
      component: load('Setting/SignalR'),
    },
    {
      path: '/:locale/api',
      name: 'api',
      component: load('Setting/API'),
    },
    {
      path: '/:locale/tools',
      name: 'tools',
      component: load('Setting/Tools'),
    },
    {
      path: '/:locale/tools/item-printer-links',
      name: 'item-printer-links',
      component: load('Setting/Tools/ItemPrinterLinks'),
    },
    {
      path: '/:locale/tools/item-proposition-links',
      name: 'item-proposition-links',
      component: load('Setting/Tools/ItemPropositionLinks'),
    },
    {
      path: '/:locale/tools/item-rank-links',
      name: 'item-rank-links',
      component: load('Setting/Tools/ItemRankLinks'),
    },
    {
      path: '/:locale/tools/batch-assign/:name?',
      name: 'batch-assign',
      component: load('Setting/Tools/BatchAssign'),
    },
    {
      path: '/:locale/tools/pos-matrix-template-link',
      name: 'pos-matrix-template-link',
      component: load('Setting/Tools/POSMatrixTemplateLink'),
    },
    {
      path: '/:locale/tools/lightspeed',
      name: 'lightspeed',
      component: load('Setting/Tools/LightSpeed'),
    },
    {
      path: '/:locale/account/credentials',
      name: 'credentials',
      component: load('Account/Credentials'),
    },
    {
      path: '/:locale/changehistory',
      name: 'changehistory',
      component: load('Setting/ChangeHistory'),
    },
    {
      path: '/:locale/timeslot',
      name: 'timeslot',
      component: load('Setting/Timeslot')
    },
    {
      path: '/:locale/booking',
      name: 'booking',
      component: load('Layout', { booking: true }),
      redirect: {
        name: 'booking-settings',
      },
      children: [
        {
          path: 'reservations',
          name: 'booking:reservations',
          component: load('Reservations', { booking: true }),
        },
        {
          path: 'timeline',
          name: 'booking:timeline',
          component: load('Timeline', { booking: true }),
        },
        {
          path: 'calendar',
          name: 'booking:calendar',
          component: load('Calendar', { booking: true }),
        },
        {
          path: ':uid?',
          name: 'booking:settings:form',
          component: load('Settings/Form', { booking: true }),
        },
      ]
    },
    {
      path: '/:locale/click-and-collect',
      name: 'click-and-collect',
      redirect: {
        name: 'click-and-collect:storefronts'
      },
      children: [
        {
          path: 'storefronts',
          name: 'click-and-collect:storefronts',
          component: load('Storefronts/Layout', { clickAndCollect: true }),
          redirect: {
            name: 'click-and-collect:storefronts:list',
          },
          children: [
            {
              path: '',
              name: 'click-and-collect:storefronts:list',
              component: load('Storefronts/List', { clickAndCollect: true }),
            },
            {
              path: ':uid',
              name: 'click-and-collect:storefronts:show',
              component: load('Storefronts/Show', { clickAndCollect: true }),
            }
          ]
        },
        {
          path: 'branding',
          name: 'click-and-collect:branding',
          component: load('Branding/Layout', { clickAndCollect: true }),
          redirect: {
            name: 'click-and-collect:branding:list'
          },
          children: [
            {
              path: '',
              name: 'click-and-collect:branding:list',
              component: load('Branding/List', { clickAndCollect: true })
            },
            {
              path: ':uid',
              name: 'click-and-collect:branding:update',
              component: load('Branding/Update', { clickAndCollect: true })
            },
            {
              path: 'create',
              name: 'click-and-collect:branding:create',
              component: load('Branding/Create', { clickAndCollect: true })
            }
          ]
        }
      ]
    },
    {
      path: '/:locale/page-not-found',
      name: 'pagenotfound',
      component: load('PageNotFound'),
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: () => `/${(locale || 'fr')}/page-not-found`
    }
  ]

  // create router instance
  const router = createRouter({
    history: createWebHistory(),
    routes
  })

  // navigation guards
  router.beforeEach(async (to, from, next) => {
    const paramsLocale = to.params.locale as string;

    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (!SUPPORT_LOCALES.includes(paramsLocale)) {
      return next(`/${locale}`)
    }

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale)) {
      await loadLocaleMessages(i18n, paramsLocale)
    }

    // set i18n language
    setI18nLanguage(i18n, paramsLocale)

    if (to.meta.isPublic) {
      return next();
    } else {
      await authState.dispatch('getGlobalUserAsync')
        .then((user : any) => { return user ? next() : next({ name: 'home', params: { locale: paramsLocale } }); })
        .catch((err: any) => { return next({ name: 'login', params: { locale: paramsLocale } }); });
    }
  })

  return router
}
