//import { useI18n } from "vue-i18n";
import { ClickAndCollectLanguages } from "@/ClickAndCollect/types/ClickAndCollectLanguages"
import { i18n } from "@/i18n";

export default function useLocalization() {
    const lang = (): string => i18n.mode === 'legacy' ? i18n.global.locale : (i18n.global.locale.valueOf() as any).value;
    const priority = (language?: string): Array<string> => {
        language ??= lang();
        return language == 'fr' ? ['fr', 'nl', 'en'] :
               language == 'nl' ? ['nl', 'fr', 'en'] :
               ['en', 'fr', 'nl'];
    }

    const field = (fieldRoot: string, language?: string): string | null => {
        language ??= lang();
        return language ? fieldRoot + '_' + language.toLowerCase() : null;
    }
    const localize = (obj: any, fieldRoot: string = 'name', language?: string): string | null => {
        if (!obj) return null;
        const f = field(fieldRoot, language);
        return f ? obj[f] ?? null : null;
    }

    const localizeName =
        (obj: any, language?: string): string | null => localize(obj, language);
    const localizeDescription =
        (obj: any, language?: string): string | null => localize(obj, 'description', language)

    const localizeValues = (valueFr: string, valueNl: string, valueEn: string, language?: string): string | null => {
        language ??= lang();
        switch (language) {
            case 'fr': return valueFr;
            case 'nl': return valueNl;
            case 'en': return valueEn;
            default: return null;
        }
    }

    const fieldName = (language?: string) => field('name', language);
    const fieldDescription = (language?: string) => field('description', language);

    const ordered = (obj: any, fieldRoot: string = 'name', language?: string): Map<string, string> | null => {
        if (!obj) return null;

        language ??= lang();

        const r = new Map<string, string>();
        priority(language).map((l) => {
            const f = field(fieldRoot, l);
            if (f) r.set(l, obj[f]);
        });
        return r;
    }
    const orderedNames = (obj: any, language?: string) => ordered(obj, 'name', language);

    const orderedHtml = (obj: any, fieldRoot: string = 'name', language?: string): string | null => {
        if (!obj) return null;

        language ??= lang();
        // obj = normalize(obj, fieldRoot, language); // => If we want all languages to display a value, even if empty

        let insideVals = ''; let def: string | null = null;
        priority(language).map((l, idx) => {
            const c = idx == 0 ? 'primary' :
                      idx == 1 ? 'secondary' :
                      'ternary';
            const loc = localize(obj, fieldRoot, l);

            if (![undefined, null, '', def].includes(loc))
                insideVals +=
                    `<div class='ordered-name-${c} lang-${l}'>
                        <span class="fi fi-${l === 'en' ? 'gb' : l} mr-2"></span>
                        ${loc}
                    </div>`;
            def ??= loc;
        });
        return `<div class='ordered-names'>${insideVals}</div>`
    }
    const orderedNamesHtml = (obj: any) => orderedHtml(obj)
    const orderedDescriptionsHtml = (obj: any) => orderedHtml(obj, 'description')

    const normalize = (obj: any, fieldRoot: string = 'name', language?: string): any => {
        language ??= lang();

        const p = priority(language);
        let main: string = '';

        // Find the first field in the language prioritization list not empty (main)
        for (let idx = 0; idx < p.length; idx++) {
            if (Object.keys(obj).includes(field(fieldRoot, p[idx]) ?? '') &&
               (obj[field(fieldRoot,p[idx]) ?? ''] ?? '' !== '')) {
               main = field(fieldRoot, p[idx])!;
               break;
            }
        }

        if (main === '') return obj;
        else p.map((l) => {
            if ([undefined, null, ''].includes(obj[field(fieldRoot, l)!]))
                obj[field(fieldRoot, l)!] = obj[main];
        })
    }
    const normalizeName = (obj: any, language?: string): any => normalize(obj);
    const normalizeDescription = (obj: any, language?: string): any => normalize(obj, 'description');

    const localizeClickAndCollectLanguages = (obj: Record<ClickAndCollectLanguages, string | null>, language?: string): string => {
        language ??= lang()
        switch (language) {
            case 'fr': return obj.fr ?? ''
            case 'nl': return obj.nl ?? ''
            default: return obj.en ?? ''
        }
    }

    return {
        lang,
        priority,
        field,
        localize,
        localizeName,
        localizeDescription,
        localizeValues,
        fieldName,
        fieldDescription,
        ordered,
        orderedNames,
        orderedNamesHtml,
        orderedDescriptionsHtml,
        normalize,
        normalizeName,
        normalizeDescription,
        orderedHtml,
        localizeClickAndCollectLanguages,
    };
}
