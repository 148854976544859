import Api from '@/plugins/api'
import { authState } from '@/states/auth'
import { AxiosInstance } from 'axios'
import Config from '@/plugins/config';

export default class BaseProvider {
    public async getApi(version: number = 1): Promise<AxiosInstance> {
        const accessToken = await authState.dispatch('getAccessTokenAsync');
        if (!accessToken) return Promise.reject('No access token');
        return Api.authWithVersion(version, accessToken);
    }

    public async getPublicApi(version: number = 1): Promise<AxiosInstance> {
        return Api.publicWithVersion(version);
    }

    public async getPublicApiV1():Promise<AxiosInstance> {
        return this.getPublicApi(1);
    }

    public async getPublicApiV2():Promise<AxiosInstance> {
        return this.getPublicApi(2);
    }

    public async getApiV1(): Promise<AxiosInstance> {
        return this.getApi(1);
    }

    public async getApiV2(): Promise<AxiosInstance> {
        return this.getApi(2);
    }

    public async getWebsocket(path: string, version: number = 1): Promise<WebSocket> {
        const accessToken = await authState.dispatch('getAccessTokenAsync');
        const baseUrl = Config.baseAPI();
        const baseWebsocket = baseUrl.replace("https", "wss").replace("http", "ws");
        if (!accessToken) return Promise.reject('No access token');
        const queryAppendix = path.indexOf("?") > 1?"&":"?";
        const websocketUrl =  baseWebsocket + '/api/v' + version + path + queryAppendix + "access_token="+accessToken;
        return new WebSocket(websocketUrl);
    }

    protected received<T>(response: any, constructorFn?: (data: any) => T, map: boolean = true): any {
        if (constructorFn) {
            if (map) return response?.data?.map((d: any) => constructorFn(d));
            else return constructorFn(response?.data)
        }
        return response?.data;
    }

    public generateDelay(): number {
        return Math.floor(Math.random() * (750 - 100 + 1)) + 100;
    }
}
